body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
}

.navLinkMenu {
  color: #333;
  text-decoration: none;
  font-size: 14px;
}

.tableHeader {
  background-color: #f2f3f2;
  color: #2e3230;
  font-size: 12px;
  font-weight: 800 !important;
  text-transform: uppercase;
}

.MuiDataGrid-columnHeaders {
  border-radius: 0 0 0 0 !important;
}

.Mui-even {
  background-color: #fafafa !important;
}

.Mui-odd {
  background-color: #f5f5f5 !important;
}

.counselor-table .MuiDataGrid-columnSeparator {
  display: none !important;
}

.hoverPointer {
  cursor: pointer;
}

.MuiMobileStepper-dot {
  background-color: rgba(255, 255, 255, 0.45) !important;
  color: #fff;
}

.MuiMobileStepper-dotActive {
  background-color: rgba(29, 188, 157, 1) !important;
  color: #fff;
}

.MuiDataGrid-toolbarContainer {
  background-color: rgb(0, 54, 46);
  color: #fff !important;
}

.MuiDataGrid-toolbarContainer > button {
  color: #fff !important;
}

.messages {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 10px;
  padding: 16px 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #f5f5f5;
  position: relative;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: black;
  margin-left: 25%;
  background: #dff2ee;
  background-attachment: fixed;
  position: relative;
}

.source {
  color: #1dbc9d;
  text-decoration: none;
  font-style: italic;
  font-size: 10px;
}

.mobileDialog {
  margin: 0 !important;
  width: 95% !important;
}

.missing-phone {
  color: #ff0000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.program-for-print table,
.program-for-print th,
.program-for-print td {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding: 8px;
}

.MuiRating-iconEmpty {
  color: #f4f0dc !important;
  border-color: #f4f0dc;
}

.PhoneInputInput {
  border: 0;
  background: none;
  outline: none;
}

.PhoneInputCountry {
  display: none !important;
}

.MuiChip-deleteIcon {
  margin-left: 4px !important;
}

* {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.show-scrollbar {
  scrollbar-width: auto;
}

.show-scrollbar::-webkit-scrollbar {
  display: block;
  width: 8px;
  height: 8px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* CampusReel Styles */
.campusreel-modal {
  z-index: 2000 !important;
}

.campusreel-videos-carousel {
  height: auto !important;
}

.campusreel-carousel-scroll {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
  background: white !important;
  max-width: none !important;
  min-width: 100% !important;
}

.campusreel-video-aspect-ratio {
  max-width: none !important;
  min-width: 350px !important;
  width: auto !important;
  height: 297px !important;
  border-radius: 16px !important;
}

@media (max-width: 768px) {
  .campusreel-video-aspect-ratio {
    height: 175px !important;
  }
}

.campusreel-video-item-info {
  width: 93% !important;
}

.campusreel-carousel-powered-by {
  display: none !important;
}
